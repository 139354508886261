//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TagList",
  props: {
    tags: {
      type: Array,
      default: Array,
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Magellan",
  mixins: [Meta],
  layout: "normal",
  data() {
    return {
      msg: "Magellan",
      meta: {
        title: "Magellan",
        type: "article",
      },
    };
  },
  watch: {
    "$window.width"() {
      console.log(`width: ${this.$window.width}`);
    },
    "$window.height"() {
      console.log(`height: ${this.$window.height}`);
    },
    "$window.pageYOffset"() {
      console.log(`pageYOffset: ${this.$window.pageYOffset}`);
    },
  },
  mounted() {
    this.sticky = new Foundation.Sticky($("#magellan"));
    this.magellan = new Foundation.Magellan($("#magellan"), {
      // These options can be declarative using the data attributes
      animationEasing: "swing",
    });
  },
};

//
//
//
//
//
//

export default {
  data() {
    return {
      dataLoadFinish: false,
    };
  },
  mounted() {
    window.addEventListener("load", () => {
      this.dataLoadFinish = true;
    });
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  mixins: [Meta],
  head() {
    return {
      title: process.env.siteName,
      titleTemplate: false,
    };
  },
  watch: {
    "$window.width"() {
      console.log(`width: ${this.$window.width}`);
    },
    "$window.height"() {
      console.log(`height: ${this.$window.height}`);
    },
    "$window.pageYOffset"() {
      console.log(`pageYOffset: ${this.$window.pageYOffset}`);
    },
  },
};

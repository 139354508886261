//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: "noFooter",
  async asyncData({ $content, params }) {
    const articles = await $content("articles", params.slug)
      .where({
        "author.name": {
          $regex: [params.author, "i"],
        },
      })
      .without("body")
      .sortBy("createdAt", "asc")
      .fetch();
    return {
      articles,
    };
  },
  head() {
    return {
      title: `Articles Author - ${this.captialise(this.$route.params.author)}`,
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: `${this.$config.baseUrl}/author/${this.$route.params.author}`,
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("en", options);
    },
    captialise(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
};

import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=38f33b66&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SingleBack: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/svg/SingleBack.vue').default,DoubleBack: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/svg/DoubleBack.vue').default,SingleFwd: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/svg/SingleFwd.vue').default,DoubleFwd: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/svg/DoubleFwd.vue').default})

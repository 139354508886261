import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=4b932f11&"
import script from "./_page.vue?vue&type=script&lang=js&"
export * from "./_page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/TheHeader.vue').default,ArticleList: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/ArticleList.vue').default,TagList: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/TagList.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const social = [
  {
    name: "instagram",
    link: "https://www.instagram.com/muraie_jin/",
    classes: "fa fa-instagram",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/jin-muraie",
    classes: "fa fa-linkedin-square",
  },
  {
    name: "github",
    link: "https://github.com/annrie",
    classes: "fa fa-github",
  },
  {
    name: "twitter",
    link: "https://twitter.com/muraie_jin",
    classes: "fa fa-twitter-square",
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/muraiejin",
    classes: "fa fa-facebook-official",
  },
];

export default {
  name: "TheFooter",
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
  data() {
    return {
      social,
    };
  },
  mounted() {
    const hash = this.$route.hash;
    if (hash && hash.match(/^#.+$/)) {
      this.$scrollTo(hash);
    }
  },
};

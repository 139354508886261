//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "DropdownMenu",
  mixins: [Meta],
  data() {
    return {
      msg: "Dropdown Menu",
      meta: {
        title: "Dropdown Menu",
        type: "article",
      },
    };
  },
  mounted() {
    this.dropdownMenu = new Foundation.DropdownMenu($("#dropdown-menu"), {
      // These options can be declarative using the data attributes
      hoverDelay: 300,
    });
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters({
      getAll: "json/getAll",
    }),
  },
  mounted() {
    this.offCanvas = new Foundation.OffCanvas($("#offCanvas"));
    this.drillDown = new Foundation.Drilldown($("#drillDown"), {
      // These options can be declarative using the data attributes
      animationDuration: 1500,
    });
  },
};

//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  // layout: "default",
};

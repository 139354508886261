//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "AccordionMenu",
  mixins: [Meta],
  data() {
    return {
      msg: "Accordion Menu",
      meta: {
        title: "Accordion Menu",
        type: "article",
      },
    };
  },
  mounted() {
    this.accordionMenu = new Foundation.AccordionMenu($("#accordion-menu"), {
      // These options can be declarative using the data attributes
      slideSpeed: 500,
      multiOpen: true,
    });
  },
};

import { render, staticRenderFns } from "./noFooter.vue?vue&type=template&id=5a6fefdc&scoped=true&"
import script from "./noFooter.vue?vue&type=script&lang=js&"
export * from "./noFooter.vue?vue&type=script&lang=js&"
import style0 from "./noFooter.vue?vue&type=style&index=0&id=5a6fefdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a6fefdc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OffCanvas: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/OffCanvas.vue').default,MainNav: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/MainNav.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Tabs",
  mixins: [Meta],
  data() {
    return {
      msg: "Tabs",
      meta: {
        title: "Tabs",
        type: "article",
      },
    };
  },
  mounted() {
    this.tabs = new Foundation.Tabs($("#tabs"), {
      // These options can be declarative using the data attributes
      matchHeight: false,
    });
  },
};

//
//
//
//

const now = new Date();
const year = now.getFullYear();

export default {
  data() {
    return {
      year,
    };
  },
};

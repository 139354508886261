//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Reveal",
  mixins: [Meta],
  data() {
    return {
      msg: "Reveal",
      meta: {
        title: "Reveal",
        type: "article",
      },
    };
  },
  mounted() {
    this.reveal = new Foundation.Reveal($("#reveal-dialog"), {
      // These options can be declarative using the data attributes
      animationIn: "spin-in",
      animationOut: "spin-out",
    });
  },
  methods: {
    // Added the below openReveal method for two reasons:
    // 1) There was a bug preventing the reveal from working once
    // you navigated away and back to the reveal component.
    // 2) Most dialogs will need to be opened using code.
    openReveal() {
      this.reveal.open();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Slider",
  mixins: [Meta],
  data() {
    return {
      msg: "Slider",
      dataValue: 50,
      dataEnd: 200,
      meta: {
        title: "Slider",
        type: "article",
      },
    };
  },
  mounted() {
    this.slider = new Foundation.Slider($("#slider"), {
      // These options can be declarative using the data attributes
      step: 10,
    });
    this.slider.$element.on("moved.zf.slider", () => {
      this.dataValue = this.slider.inputs.val();
    });
  },
};

import { render, staticRenderFns } from "./default.vue?vue&type=template&id=50289bc7&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OffCanvas: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/OffCanvas.vue').default,MainNav: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/MainNav.vue').default})

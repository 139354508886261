//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";

export default {
  name: "MainNav",
  computed: {
    tagName() {
      if (this.$route.name === "index") {
        return "h1";
      }
      return "p";
    },
    ...mapGetters({
      getAll: "json/getAll",
    }),
  },
  mounted() {
    // this.offCanvas = new Foundation.OffCanvas($('#offCanvas'));
    this.smoothScroll = new Foundation.SmoothScroll($("#mainNav"));
    this.sticky = new Foundation.Sticky($("#mainNav"));
    this.dropDown = new Foundation.DropdownMenu($("#dropDown"));
  },
};

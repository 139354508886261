import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=4d81dd8a&"
var script = {}
import style0 from "./TheHeader.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSearchInput: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/AppSearchInput.vue').default})

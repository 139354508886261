//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SingleFwd",
};

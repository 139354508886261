//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Accordion",
  mixins: [Meta],
  data() {
    return {
      msg: "Accordion",
      meta: {
        title: "Accordion",
        type: "article",
      },
    };
  },
  computed: {
    page() {
      return this.$store.state.pages.page;
    },
  },
  mounted() {
    this.accordion = new Foundation.Accordion($("#accordion"), {
      // These options can be declarative using the data attributes
      slideSpeed: 500,
      multiExpand: true,
    });
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Orbit",
  mixins: [Meta],
  data() {
    return {
      msg: "Orbit",
      meta: {
        title: "Orbit",
        type: "article",
      },
    };
  },
  mounted() {
    this.orbit = new Foundation.Orbit($("#orbit"), {
      // These options can be declarative using the data attributes
      timerDelay: 2000,
    });
  },
};

import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=63152a39&"
import script from "./ArticleList.vue?vue&type=script&lang=js&"
export * from "./ArticleList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Pagination: require('/Users/annrie/Desktop/Bitbucket/nuxtation-blog/src/components/blog/Pagination.vue').default})

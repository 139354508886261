//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Dropdown",
  mixins: [Meta],
  data() {
    return {
      msg: "Dropdown",
      meta: {
        title: "Dropdown",
        type: "article",
      },
    };
  },
  mounted() {},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Meta from "~/assets/mixins/meta";

export default {
  name: "Drilldown",
  mixins: [Meta],
  data() {
    return {
      msg: "Drilldown Menu",
      meta: {
        title: "Drilldown Menu",
        type: "article",
      },
    };
  },
  mounted() {
    this.drilldown = new Foundation.Drilldown($("#drilldown"), {
      // These options can be declarative using the data attributes
      animationDuration: 500,
    });
  },
};
